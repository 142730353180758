import routerOptions0 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__6g5ijljcqfpo5rncc2jjbouuy4/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/apps/nuxt-front/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}